.themeToggle {
  background-color: var(--color-border);
  border-radius: 2rem;
  border: 1px solid transparent;
  padding: 4px;
  transition: var(--transition-ui);
  display: inline-block;
  cursor: pointer;

  .inner {
    display: flex;
    isolation: isolate;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 50%;
      border-radius: var(--border-radius-r);
      background-color: var(--color-toggle);
      left: 0;
      transition: var(--transition-ui);
      transform: translate(100%, 0);
    }

    & > span {
      padding: 6px;

      & > svg {
        display: block;
        width: 15px;
        height: 15px;
        object-position: center;

        path {
          stroke: var(--color-icon);
          transition: var(--transition-ui);
        }
      }
    }
  }

  body[data-theme="light"] & {
    background-color: transparent;
    border-color: var(--color-border-soft);

    .inner {
      &:after {
        transform: translate(0, 0);
      }

      & > span {
        &:nth-child(1) > svg path {
          stroke: var(--color-background-modal);
        }
      }
    }
  }
}
