.menu {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--m) 0;
}

.title.title {
  h1 {
    margin-bottom: 0;
  }

  small {
    display: block;
  }
}

.actions {
  display: flex;
  gap: var(--m-s);
  align-items: center;
}

.searchInput.searchInput {
  margin-bottom: 0;
}

.info {
  display: flex;
  padding: var(--m-xs);
  gap: var(--m-xs);

  a.infoTitle {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .iconPanel {
    svg {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }

    &.select svg path {
      stroke: var(--color-primary);
    }

    &.insert svg path {
      stroke: var(--color-green);
    }

    &.update svg {
      transform: rotate(90deg);

      path {
        stroke: var(--color-yellow);
      }
    }

    &.delete svg path {
      stroke: var(--color-red);
    }

    &.database svg path {
      stroke: var(--color-text);
    }
  }

  .textsPanel {
    flex-shrink: 1;
    max-width: 100%;
  }

  h2 {
    font-size: inherit;
    font-weight: 600;
    margin-bottom: var(--m-xxxxs);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.01em;
  }

  .footer {
    display: flex;
    gap: var(--m-xs);
    color: var(--color-text-soft);
    font-size: var(--font-size-s);

    svg {
      path {
        stroke: var(--color-text-soft);
      }
    }

    & > * {
      display: flex;
      gap: var(--m-xxxxs);
      align-items: center;
    }

    .author {
      color: var(--color-blue);
    }
  }
}

.tableName {
  display: flex;
  align-items: center;
  gap: var(--m-xs);
}

.importerIcon {
  color: var(--color-primary);
}
