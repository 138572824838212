.rangeControl {
  display: flex;
  align-items: center;
}

.inputWrapper {
  flex: 1;
}

.inputSeparator {
  margin: 0 var(--m-s);
  width: 14px;
  height: 3px;
  margin-top: 25px;
  background-color: var(--color-text-on-primary);
}

.formTooltip {
  margin-left: 5px;
  transform: translateY(2px);
}

.formLabel {
  display: inline;
}

.validationPlaceholder {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
}

.placeholderIcon {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 28px;
  border: 8px solid var(--color-background-small-button-selected);
  background: var(--color-text-on-tertiary);
  margin: 0 auto var(--m-s);
  fill: var(--color-background-modal);
}
