.wrapper {
  & > * {
    & > * + * {
      margin-top: var(--m-l);
    }
  }

  h3 {
    font-size: var(--font-size-xxl);
    font-weight: 500;
    margin: 0;
  }

  small {
    font-weight: 600;
  }
}
