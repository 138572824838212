@import "./Common.module.scss";

.box {
  color: var(--color-text-strong);

  h1 {
    white-space: nowrap;
  }

  p {
    font-size: var(--font-size-xl);

    &:last-of-type {
      margin-bottom: var(--m-xl);
    }
  }
}
