.topBar {
  padding: var(--m-xs) var(--m);
  border-bottom: 1px solid var(--color-border-soft);

  & > * {
    display: flex;
    gap: var(--m);
    justify-content: space-between;
    align-items: center;
  }
}

.separator {
  margin: 0 auto;
}

.settingsButton,
.profileButton {
  align-self: normal;
  aspect-ratio: 1;
}

.profileButton {
  padding: var(--m-xs);
}
