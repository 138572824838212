.tableflow {
    svg {
        width: auto;
        display: block;
    }

    &.small {
        svg {
            height: 20px;
        }
    }

    &.normal {
        svg {
            height: 26px;
        }
    }

    &.big {
        svg {
            height: 32px;
        }
    }
}

:global {
    //body[data-theme="light"] & {
    svg {
        path.letter {
            fill: var(--color-tableflow-text) !important;
        }
    }
    //}
}
