.tabs {
  background: transparent;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--m-s);
  gap: var(--m-xs);
  border-bottom: 1px solid var(--color-border);
}

.tabBtn {
  float: left;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: var(--m-xxs) var(--m-xxxs);
  border-bottom: 2px solid transparent;
  color: var(--color-text-soft);
  border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;

  &:focus-visible {
    background-color: var(--color-background-modal);
  }
  &:hover,
  &.active {
    border-bottom-color: var(--color-primary);
    color: var(--color-text);
  }
  &.active {
    cursor: default;
  }
}
