.importer {
  h1 {
    margin: var(--m-xxl) 0;
  }
}

.top.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--m);
  gap: var(--m-xs);
  align-items: center;
  display: flex;
  align-items: center;
  gap: var(--m-xs);

  .heading {
    display: flex;
    gap: var(--m-s);
  }

  .title {
    display: flex;
    align-items: center;
    gap: var(--m-xs);
  }

  h1 {
    margin: 0;
    flex-grow: 1;

    input {
      width: 100%;
      padding: var(--m-xxxs) 0;
      border-radius: var(--border-radius-2);

      &:hover,
      &:focus {
        background-color: var(--color-background-modal);
      }

      &:focus {
        padding: var(--m-xxxs) var(--m-xs);
      }
    }
  }
}

// This should be removed when the UI library is updated
.miniBadge.miniBadge {
  display: inline-flex;
  align-items: center;
  padding: var(--m-xxxs);
  border-radius: 50%;
  background-color: var(--color-background-modal);
  aspect-ratio: 1;
  position: relative;
  font-weight: bold;
  font-size: var(--font-size-xs);
  line-height: 0;
  color: currentColor;
}

.actions {
  display: flex;
  gap: var(--m-xs);
  align-items: center;
}

.subTitle {
  margin-left: calc(var(--m-xxxxs) * -1);
  gap: var(--m-xxxs);
  display: flex;
  align-items: center;
}
