.container {
  display: flex;
  height: 100%;
}

.wrapper {
  display: flex;
  flex: 1;
}

.content {
  padding: var(--m);
  flex: 1;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-size: var(--font-size-xxl);
    font-weight: 500;
    margin: 0 0 var(--m-s);
  }

  small {
    color: var(--color-text-soft);
  }
}
