.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
