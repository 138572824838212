$radius: var(--border-radius-2);

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: $radius;
  outline: 1px solid var(--color-border);
  table-layout: fixed;

  .thead {
    display: table-header-group;
  }

  .tbody {
    display: table-row-group;
  }

  .tr {
    display: table-row;
  }

  .td {
    display: table-cell;
    vertical-align: middle;
    max-width: 300px;
    height: 48px;
  }

  .caption {
    display: table-caption;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-background-modal);
    padding: 0 var(--m-s);
    border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
  }

  .thead {
    .tr {
      margin-bottom: var(--m-s);

      .td {
        font-weight: 600;
        white-space: nowrap;
        padding: var(--m-xxxs) var(--m-s);
        border-bottom: 1px solid var(--color-border);
        color: var(--color-text-soft);

        &:first-of-type {
          border-radius: $radius 0 0 0;
        }

        &:last-child {
          border-radius: 0 $radius 0 0;
        }
      }
    }
  }

  &.fixHeader {
    border-radius: 0 0 $radius $radius;

    .thead {
      position: sticky;
      top: 0;
      z-index: 1;
      outline: 1px solid var(--color-border);

      .tr {
        margin-bottom: var(--m-s);

        .td {
          background-color: var(--color-background-modal);

          &:first-of-type,
          &:last-child {
            border-radius: 0;
          }
        }
      }
    }
  }

  .tbody {
    .tr {
      .td {
        vertical-align: middle;
        padding: var(--m-xxxs) var(--m-s);
        font-weight: 400;

        & > span,
        & > small {
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          overflow: hidden;
        }

        &.highlight {
          font-weight: 500;
        }

        &.element {
          padding: 0 var(--m-s);
        }
      }

      &:hover {
        box-shadow: 0 0 0 2px var(--color-border);
        position: relative;
      }

      &:last-child {
        border-radius: 0 0 $radius $radius;

        .td {
          border-bottom-color: transparent;

          &:first-of-type {
            border-radius: 0 0 0 $radius;
          }

          &:last-child {
            border-radius: 0 0 $radius 0;
          }
        }
      }
    }
  }

  &.zebra {
    background-color: var(--color-background-modal);

    .tbody {
      .tr {
        &:nth-child(odd) {
          .td {
            background-color: var(--color-background);
          }
        }
      }
    }
  }

  &.dark {
    background-color: var(--color-background);

    .thead .tr .td {
      background-color: var(--color-background-modal);
    }
  }

  &.light {
    background-color: var(--color-background-modal);
  }

  &.dark,
  &.light {
    .tbody {
      .tr {
        &:hover {
          box-shadow: none;
          position: static;
        }

        .td {
        }

        &:first-of-type .td {
          padding-top: var(--m-s);
        }

        &:last-of-type .td {
          padding-bottom: var(--m-s);
        }
      }
    }
  }
}

.emptyMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  text-align: center;
}
