.box {
  h1,
  h2 {
    color: var(--color-text-strong);
  }

  h1 {
    margin: var(--m) 0 var(--m);
  }

  h2 {
    margin: var(--m) 0 var(--m-s);
  }

  svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  p {
    margin-bottom: var(--m-s);

    &:last-of-type {
      margin-bottom: var(--m);
    }
  }
}

.top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--m);

  & > a {
    cursor: pointer;

    svg {
      path {
        stroke: var(--color-text);
      }
    }
  }
}
