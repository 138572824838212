:root {
  // BACKGROUND

  --color-primary: #{$primary-600};
  --color-primary-hover: #{$primary-700};
  --color-primary-focus: #{$primary-600};
  --color-primary-disabled: #{$primary-200};
  --color-primary-button-disabled: #3f3b55;

  --color-secondary: #{$gray-800};
  --color-secondary-hover: #{$gray-600};
  --color-secondary-focus: #{$gray-800};
  --color-secondary-disabled: #{$gray-700};

  // TEXT

  --color-text-on-primary: #{$base-white};
  --color-text-on-primary-disabled: #{$gray-500};
  --color-text-on-primary-button-disabled: #{$base-white};

  --color-text-on-secondary: #{$gray-100};
  --color-text-on-secondary-disabled: #{$gray-600};

  --color-progress-bar: #{$green-600};

  --color-success: rgba(18, 183, 106, 0.88);
  --color-emphasis: #{$blue-light-500};
  --color-error: rgba(252, 93, 93, 0.88);
  --color-attention: rgba(248, 203, 44, 0.88);

  --color-importer-link: #2275d7;

  --blue-light-500: #{$blue-light-500}; // Deprecated
  --color-green-ui: var(--color-progress-bar); // Deprecated
  --color-green: var(--color-success); // Deprecated
  --color-blue: #{$blue-light-500}; // Deprecated
  --color-red: rgba(252, 93, 93, 0.88); // Deprecated
  --color-yellow: rgba(248, 203, 44, 0.88); // Deprecated
  --importer-link: var(--color-importer-link); // Deprecated
}
