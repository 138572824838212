.logo {
  margin: var(--m) 0;

  svg {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.container {
  min-width: 360px;
}

.extendedContainer {
  min-width: 820px;
}

.formTop {
  display: flex;
  justify-content: space-between;

  .action {
    cursor: pointer;
    padding: var(--m-xxs);
    margin: calc(var(--m-xxs) * -1) calc(var(--m-xxs) * -1) 0 0;

    svg {
      stroke: var(--color-text-soft);
    }

    &:hover {
      svg {
        stroke: var(--color-text);
      }
    }
  }
}

.title {
  font-weight: 600;
  margin: 0 0 var(--m);
  color: var(--color-text);
  font-size: var(--font-size-xl);

  & + :not(.intro) {
    margin-top: var(--m-l);
  }

  &.smallSpace {
    margin: 0 0 var(--m-xs);
  }

  &.smallInnerSpace {
    h1 {
      margin-bottom: 0;
    }
  }
}

.welcome {
  margin: 0 0 var(--m-mm);
  text-align: center;
  font-weight: 400;
  color: var(--color-text-soft);
}

.intro {
  line-height: 1.6;
  margin: 0 0 var(--m-l);
  font-weight: 400;
  color: var(--color-text-soft);
}

.sectionTitle {
  display: block;
  margin-bottom: var(--m-xxxs);
  text-align: left;
}

.texts {
  p {
    margin-bottom: var(--m);
  }
}

.errors {
  color: var(--color-text-error);
  margin-top: var(--m-l);

  p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: var(--m-xxs);
    text-align: left;
  }
}

.actions {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  gap: var(--m-xl);

  &.compact {
    margin-top: var(--m-xxxs);
  }
}

.actionsVertical {
  display: flex;
  flex-direction: column;
  gap: var(--m);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-text-soft);
  padding-bottom: var(--m);
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--color-text-soft);
}

.separator:not(:empty)::before {
  margin-right: 0.5em;
}

.separator:not(:empty)::after {
  margin-left: 0.5em;
}

.footer {
  text-align: center;
  margin-top: var(--m-l);
  color: var(--color-text-soft);

  a {
    color: var(--color-primary);
  }
}

.doubleLine {
  display: flex;
  gap: var(--m);

  & > * {
    flex: 1 1 1px;
  }
}

.codeLine {
  margin-bottom: var(--m);
  overflow: hidden;
  text-overflow: ellipsis;
  padding: var(--m-s);
  background-color: rgba(black, 0.1);
  border-radius: var(--border-radius-1);
  font-family: monospace;
  border: 1px solid rgba(black, 0.2);
  white-space: nowrap;
}

.inputWithIcon {
  display: flex;
  align-items: center;
  gap: var(--m-xs);

  & > *:first-child {
    flex: 1 1 1px;
  }

  & > * + * {
    aspect-ratio: 1;
    padding: var(--m-xxs);
  }
}

// This should be removed when the UI library is updated
.iconFix.iconFix {
  // path {
  //   stroke: none !important;
  //   fill: var(--color-text-soft);
  // }
}

.disabledLink {
  pointer-events: none;
  color: var(--color-text-soft) !important;
}

.oauthButtons {
  padding: var(--m) 0 var(--m-mm);
  display: flex;
  flex-direction: column;
  gap: var(--m-s);
}

.oauthButton.oauthButton {
  background-color: transparent;

  svg {
    path {
      stroke: none !important;
    }
  }
}

[data-theme="light"] {
  .oauthButtonSvg path {
    fill: var(--color-text);
  }
}

.iconRed {
  color: var(--color-text-error);
}

.checkboxInput {
  padding-bottom: var(--m-xs);
}

.checkboxLabel {
  padding-left: var(--m-xxs);
  vertical-align: middle;
}

.pillInputContainer {
  margin-bottom: 20px;
}

.formControls {
  display: flex;
  margin-bottom: var(--m-xxs);

  .column {
    flex: 1;
    padding: 15px;

    .titleContainer {
      margin: 0 0 var(--m);

      .subTitle {
        font-weight: 600;
        margin: 0 0 var(--m-xs);
        color: var(--color-text);
        font-size: var(--font-size-l);
      }

      .customText {
        font-weight: 400;
        font-size: var(--font-size-xs);
      }
    }
  }
}

.inputFieldset {
  margin-top: var(--m-xs);
  margin-bottom: var(--m-xs);
}

.form {
  display: flex;
  gap: var(--m-s);
  align-items: flex-start;

  & > * {
    margin: 0;

    &:first-child {
      flex-grow: 1;
    }
  }
}
